// TrainTypeSelector.js
import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Train } from 'lucide-react';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 2px solid ${props => props.selected ? '#0d6efd' : 'transparent'};

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
`;

function TrainTypeSelector({ onSelect }) {
  return (
    <div>
      <h4 className="text-center mb-4">기차 종류 선택</h4>
      <div className="d-flex gap-4 justify-content-center">
        <StyledCard 
          className="text-center p-4" 
          onClick={() => onSelect('SRT')}
        >
          <Card.Body>
            <Train size={48} className="mb-3 text-primary" />
            <h3>SRT</h3>
            <p className="text-muted">수서고속철도</p>
            <Button variant="primary">선택</Button>
          </Card.Body>
        </StyledCard>

        <StyledCard 
          className="text-center p-4" 
          onClick={() => onSelect('KTX')}
        >
          <Card.Body>
            <Train size={48} className="mb-3 text-danger" />
            <h3>KTX</h3>
            <p className="text-muted">한국고속철도</p>
            <Button variant="danger">선택</Button>
          </Card.Body>
        </StyledCard>
      </div>
    </div>
  );
}

export default TrainTypeSelector;
