import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { User, Lock, LogIn, Eye, EyeOff, Train } from 'lucide-react';
import styled, { keyframes } from 'styled-components';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledCard = styled(Card)`
  animation: ${fadeInUp} 0.5s ease-out;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 15px;
  overflow: hidden;
`;

const GradientHeader = styled(Card.Header)`
  background: linear-gradient(120deg, #2980b9, #8e44ad);
  color: white;
  border: none;
  padding: 20px;
`;

const StyledForm = styled(Form)`
  padding: 30px;
`;

const IconInput = styled.div`
  position: relative;
  margin-bottom: 20px;

  .form-control {
    padding-left: 40px;
    height: 50px;
    border-radius: 25px;
    border: 1px solid #ced4da;
    transition: all 0.3s ease;

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(41, 128, 185, 0.25);
      border-color: #2980b9;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: #7f8c8d;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
  border-radius: 25px;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(120deg, #2980b9, #8e44ad);
  border: none;
  transition: all 0.3s ease;

  &:hover, &:focus {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
`;

const PasswordToggle = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #7f8c8d;
`;

function LoginForm({ credentials, setCredentials, onLogin, trainType }) {
  const [showPassword, setShowPassword] = useState(false);
  const [saveCredentials, setSaveCredentials] = useState(false);

  useEffect(() => {
    // 저장된 로그인 정보 불러오기
    const savedCredentials = localStorage.getItem(`${trainType}_credentials`);
    if (savedCredentials) {
      const parsed = JSON.parse(savedCredentials);
      setCredentials(parsed);
      setSaveCredentials(true); // 저장된 정보가 있으면 체크박스도 체크
    }
  }, [trainType, setCredentials]);

  const handleLogin = async () => {
    if (saveCredentials) {
      // 로그인 정보 저장
      localStorage.setItem(`${trainType}_credentials`, JSON.stringify(credentials));
    }
    onLogin();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (saveCredentials) {
      // 로그인 정보 저장
      localStorage.setItem(`${trainType}_credentials`, JSON.stringify(credentials));
    } else {
      // 체크 해제된 경우 저장된 정보 삭제
      localStorage.removeItem(`${trainType}_credentials`);
    }
    
    onLogin();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <StyledCard>
      <GradientHeader>
        <h3 className="mb-0 text-center">
          <Train size={24} className="me-2" />
          {trainType} 로그인
        </h3>
      </GradientHeader>
      <StyledForm onSubmit={handleSubmit}>
        <Alert variant="info" className="mb-4">
          {trainType}에서 사용하는 아이디와 비밀번호를 입력하세요. 계정정보는 저장되지 않습니다. <br />
          <strong>핸드폰 번호 입력시 반드시 하이픈(-)을 포함해야 합니다. (예: 010-1234-5678)</strong>
        </Alert>
        <IconInput>
          <Form.Control
            type="text"
            placeholder={`${trainType} 아이디 입력`}
            value={credentials.username}
            onChange={(e) => setCredentials({...credentials, username: e.target.value})}
          />
          <User size={20} />
        </IconInput>
        <IconInput>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder={`${trainType} 비밀번호 입력`}
            value={credentials.password}
            onChange={(e) => setCredentials({...credentials, password: e.target.value})}
          />
          <Lock size={20} />
          <PasswordToggle onClick={togglePasswordVisibility}>
            {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
          </PasswordToggle>
        </IconInput>
        <Form.Check
          type="checkbox"
          label="로그인 정보 저장"
          checked={saveCredentials}
          onChange={(e) => setSaveCredentials(e.target.checked)}
          className="mb-3"
        />
        <StyledButton type="submit">
          <LogIn size={20} className="me-2" />
          예약하러 ㄱㄱ
        </StyledButton>
      </StyledForm>
    </StyledCard>
  );
}

export default LoginForm;
