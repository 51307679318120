import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Train, Calendar, Clock, MapPin, X, Loader } from 'lucide-react';

const slideIn = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TaskCard = styled.div`
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  border-radius: 15px;
  padding: 20px;
  color: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: ${slideIn} 0.5s ease-out;
  position: relative;
`;

const TaskHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const Title = styled.h3`
  font-size: 1.25rem;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CreatedAt = styled.span`
  font-size: 0.8rem;
  opacity: 0.8;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 15px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 8px;
  font-size: 0.9rem;
`;

const StatusBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LoaderIcon = styled(Loader)`
  animation: ${rotate} 2s linear infinite;
`;

const CancelButton = styled.button`
  background: #ff4757;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #ff6b81;
    transform: translateY(-2px);
  }
`;

function TaskManager({ tasks = [], onCancelTask, maxTasks, trainType }) {
  if (!tasks || tasks.length === 0) {
    return (
      <Container>
        <TaskCard>
          <Title>
            <Train size={24} />
            실행 중인 {trainType} 매크로가 없습니다
          </Title>
        </TaskCard>
      </Container>
    );
  }

  const formatTrainTime = (time) => {
    return `${time.substring(0, 2)}:${time.substring(2, 4)}`;
  };

  return (
    <Container>
      {tasks.map((task) => (
        <TaskCard key={task.task_id}>
          <TaskHeader>
            <Title>
              <Train size={24} />
              {trainType} {task.train_no || task.train_number}
            </Title>
            <CreatedAt>
              시작: {task.created_at ? new Date(task.created_at).toLocaleString() : '알 수 없음'}
            </CreatedAt>
          </TaskHeader>
          
          <InfoGrid>
            <InfoItem>
              <Calendar size={18} />
              {task.dep_date}
            </InfoItem>
            <InfoItem>
              <Clock size={18} />
              {formatTrainTime(task.dep_time)}
            </InfoItem>
            <InfoItem>
              <MapPin size={18} />
              {task.departure}
            </InfoItem>
            <InfoItem>
              <MapPin size={18} />
              {task.arrival}
            </InfoItem>
          </InfoGrid>
          
          <StatusBar>
            <div className="flex items-center gap-2">
              <LoaderIcon size={18} />
              예약 매크로 실행 중...
            </div>
            <CancelButton onClick={() => onCancelTask(task.task_id)}>
              <X size={18} />
              취소
            </CancelButton>
          </StatusBar>
        </TaskCard>
      ))}
    </Container>
  );
}

export default TaskManager;