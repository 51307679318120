import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';
//const API_URL = 'http://52.79.101.27:8000';

export const checkLogin = async (credentials, trainType) => {
  const endpoint = trainType === 'KTX' ? '/ktx/login' : '/check_login';
  const response = await axios.post(`${API_URL}${endpoint}`, credentials);
  return response.data;
};
export const fetchStations = async (trainType) => {
  const endpoint = trainType === 'KTX' ? '/ktx/stations' : '/stations';
  const response = await axios.get(`${API_URL}${endpoint}`);
  return response.data.stations;
};

export const searchTrains = async (searchParams, trainType) => {
  const endpoint = trainType === 'KTX' ? '/ktx/search_trains' : '/search_trains';
  const response = await axios.post(`${API_URL}${endpoint}`, searchParams);
  return response.data;
};

export const reserveTrain = async (reservationData, trainType) => {
  const endpoint = trainType === 'KTX' ? '/ktx/reserve' : '/reserve';
  const response = await axios.post(`${API_URL}${endpoint}`, reservationData);
  return response.data;
};

export const checkCurrentTask = async (username, trainType) => {
  const endpoint = trainType === 'KTX' ? '/ktx/current_task' : '/current_task';
  const response = await axios.get(`${API_URL}${endpoint}/${username}`);
  return response.data;
};


export const getUserTasks = async (username, trainType) => {
  const endpoint = trainType === 'KTX' ? '/ktx/user_tasks' : '/user_tasks';
  const response = await axios.get(`${API_URL}${endpoint}/${username}`);
  return response.data;
};

export const cancelTask = async (taskId, trainType) => {
  const endpoint = trainType === 'KTX' ? '/ktx/cancel_task' : '/cancel_task';
  const response = await axios.post(`${API_URL}${endpoint}/${taskId}`);
  return response.data;
};

export const checkAccessCode = async (code) => {
    const response = await axios.post(`${API_URL}/check_access_code`, { code });
    return response.data;
  };
  
  export const generateAccessCode = async (adminCode) => {
    const response = await axios.post(`${API_URL}/generate_access_code`, { code: adminCode });
    return response.data;
  };
  
  export const getCurrentAccessCode = async () => {
    const response = await axios.get(`${API_URL}/current_access_code`);
    return response.data;
  };

export const setCardInfo = async (username, cardInfo) => {
  const response = await axios.post(`${API_URL}/set_card`, {
    username,
    card_info: cardInfo
  });
  return response.data;
};

export const getCardInfo = async (username) => {
  const response = await axios.get(`${API_URL}/get_card/${username}`);
  return response.data;
};