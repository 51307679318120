import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { CreditCard, Calendar, Lock, Hash } from 'lucide-react';
import styled from 'styled-components';
import { setCardInfo, getCardInfo } from '../utils/api';

const StyledCard = styled(Card)`
  margin-top: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const IconInput = styled.div`
  position: relative;
  margin-bottom: 15px;

  .form-control {
    padding-left: 40px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    color: #6c757d;
  }
`;

function CardManagement({ username }) {
  const [cardInfo, setCardInfoState] = useState({
    number: '',
    password: '',
    birthday: '',
    expire: ''
  });
  const [message, setMessage] = useState('');
  const [savedCard, setSavedCard] = useState(null);

  useEffect(() => {
    fetchCardInfo();
  }, [username]);

  const fetchCardInfo = async () => {
    try {
      const response = await getCardInfo(username);
      setSavedCard(response.card_info);
    } catch (error) {
      console.error('카드 정보 조회 실패:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setCardInfo(username, cardInfo);
      setMessage('카드 정보가 저장되었습니다.');
      fetchCardInfo();
    } catch (error) {
      setMessage('카드 정보 저장에 실패했습니다.');
    }
  };

  return (
    <StyledCard>
      <Card.Header>
        <div className="d-flex align-items-center gap-2">
          <CreditCard size={20} />
          <h5 className="mb-0">결제 카드 관리</h5>
        </div>
      </Card.Header>
      <Card.Body>
        <Alert variant="info" className="mb-2">
            <strong>카드 정보를 저장하면 예약 시 자동으로 결제를 시도합니다.</strong><br />
            카드 정보는 서버에 암호화되어 저장되지만, 저장을 원치 않으실 경우 이 기능을 사용하지 마세요. <br />
            결제가 실패하는 것은, 사용할 수 없는 카드이거나 카드 정보가 올바르지 않은 경우입니다. <br />
            카드 정보 삭제를 원하는 경우, 아무 데이터나 입력하여 저장 버튼을 누르세요. <br />
        </Alert>
        {savedCard && (
          <Alert variant="info" className="mb-3">
            <strong>저장된 카드 정보:</strong><br />
            카드번호: {savedCard.number}<br />
            유효기간: {savedCard.expire}
          </Alert>
        )}

        <Form onSubmit={handleSubmit}>
          <IconInput>
            <CreditCard size={18} />
            <Form.Control
              type="text"
              placeholder="카드번호 (- 없이 입력)"
              value={cardInfo.number}
              onChange={(e) => setCardInfoState({...cardInfo, number: e.target.value})}
              required
            />
          </IconInput>

          <IconInput>
            <Lock size={18} />
            <Form.Control
              type="password"
              placeholder="비밀번호 (앞 2자리)"
              value={cardInfo.password}
              onChange={(e) => setCardInfoState({...cardInfo, password: e.target.value})}
              maxLength={2}
              required
            />
          </IconInput>

          <IconInput>
            <Hash size={18} />
            <Form.Control
              type="text"
              placeholder="생년월일 (YYMMDD) 또는 사업자번호"
              value={cardInfo.birthday}
              onChange={(e) => setCardInfoState({...cardInfo, birthday: e.target.value})}
              required
            />
          </IconInput>

          <IconInput>
            <Calendar size={18} />
            <Form.Control
              type="text"
              placeholder="유효기간 (YYMM)"
              value={cardInfo.expire}
              onChange={(e) => setCardInfoState({...cardInfo, expire: e.target.value})}
              maxLength={4}
              required
            />
          </IconInput>

          <Button type="submit" variant="primary" className="w-100">
            카드 정보 저장
          </Button>
        </Form>

        {message && (
          <Alert variant="info" className="mt-3">
            {message}
          </Alert>
        )}
      </Card.Body>
    </StyledCard>
  );
}

export default CardManagement;